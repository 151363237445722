export default {
  computed: {
    accessToken() {
      return this.$store.accessToken
    },
    user() {
      return this.$store.user
    },
    counter() {
      return this.$store.counter
    },
    headers() {
      return { Authorization: `Bearer ${this.accessToken}` }
    },
    isLoggedIn() {
      return this.user && this.user.authId && this.accessToken
    },
    isBoosted() {
      return this.user && this.user.boosted
    },
    showLoginModal() {
      return this.$store.showLoginModal
    },
    showDarkMode() {
      return this.isBoosted && this.$store.user.darkMode
    },
  },
  methods: {
    isMobile() {
      return window.innerWidth < 768
    },
    async verifyVersion() {
      const { version } = await $fetch('/api/version', {
        method: 'GET',
        baseURL: this.$config.public.appBaseURL,
      })
      if (version !== this.$config.public.version) {
        this.$notify({
          type: 'info',
          text: 'กรุณา refresh เพื่อใช้งาน drinksonme เวอร์ชั่นล่าสุด',
        })
        location.reload()
        throw new Error('reload')
      }

      return true
    },
    shouldShowSpecialDrink(user) {
      if (user && user.tipsReceived?.length > 0) {
        return true
      }
      return false
    },
    getSpecialDrinks(user) {
      if (this.shouldShowSpecialDrink(user)) {
        const orderedTips = user.tipsReceived.sort((a, b) => {
          // check insertedAt type first
          if (typeof a.insertedAt === 'string') {
            a.insertedAt = new Date(a.insertedAt)
          }
          if (typeof b.insertedAt === 'string') {
            b.insertedAt = new Date(b.insertedAt)
          }
          return b.insertedAt.getTime() - a.insertedAt.getTime()
        })
        return orderedTips.map((tip) => ({
          height: this.getHeight(tip.product.drink.name),
          imageURL: tip.product.drink.imageURL,
          name: tip.product.drink.name,
        }))
      }
      return []
    },
    getHeight(name) {
      if (name.includes('Flower Kudos')) {
        return 25
      }
      if (name.includes('King Spritz')) {
        return 25
      }
      if (name.includes('Rainbow Rock')) {
        return 22
      }
      return 25
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    getAgeRangeDisplay(ageRange) {
      return this.$config.public.ageRanges.find(ar => ar.value === ageRange)?.display || 'วัยไหนก็ได้'
    },
    limitLines(event) {
      const textarea = event.target
      const lineHeight = 20
      const maxLines = 2
      const maxHeight = lineHeight * maxLines
      if (textarea.scrollHeight > maxHeight) {
        let cutOffIndex = textarea.value.length
        while (textarea.scrollHeight > maxHeight && cutOffIndex > 0) {
          cutOffIndex--
          textarea.value = textarea.value.substring(0, cutOffIndex)
        }
        this.voiceTextInput = textarea.value
      }
    },
  },
}
